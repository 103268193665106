export const environmentObj =
{
  "domainKey": "https://test.api.bambumeta.software",
  "rootPath": "admin",
  "additionalApiDomains": [],
  "cognito": {
    "aws_user_pools_id": "us-east-1_NTUVffs8D",
    "aws_user_pools_web_client_id": "3kcaffkcli6e0trq40365ulirs",
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:39185def-4c6c-4ed3-8e59-47d464acf5bf",
    "oauth": {
      "domain": "bwa-19726821-1a18-5db3-b494-14e2d206f8b7-bambu.auth.us-east-1.amazoncognito.com",
      "scope": [
        "aws.cognito.signin.user.admin",
        "email",
        "https://admin.bambumeta.software/tenant-admin",
        "openid",
        "profile"
      ],
      "redirectSignIn": "https://d11ihajn348fvp.cloudfront.net/",
      "redirectSignOut": "https://d11ihajn348fvp.cloudfront.net/",
      "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
      "email"
    ],
    "aws_cognito_signup_attributes": [
      "email"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
      "SMS",
      "EMAIL"
    ],
    "aws_cognito_password_protection_settings": {
      "passwordPolicyCharacters": [],
      "passwordPolicyMinLength": 6
    },
    "aws_cognito_verification_mechanisms": [
      "email"
    ]
  }
}